export enum TABS_CODES {
  WHAT_LEARN_CODE = '1',
  MODULES_CODE = '2',
}

export enum VIDEOS_CODES {
  CODE_ALL_TOPICS = '3007',
  CODE_MICRO_ENTREPRENEUR = '26001',
  CODE_QUECHUA_TOPICS = '3008',
}

export const BADGE_THEMES = [
  { code: 'new', source: '', class: 'primary', label: 'Nuevo' },
  { code: 'course', source: 'play-icon.svg', class: 'orange', label: 'Curso' },
  { code: 'video', source: 'play-video.svg', class: 'blue', label: 'Video' },
  { code: 'article', source: 'award-white.svg', class: 'cian', label: 'Artículo' },
  { code: 'html', source: 'award-white.svg', class: 'cian', label: 'Artículo' },
];

export const BADGE_THEMES_DASHBOARD = [
  { code: 'new', source: '', class: 'primary', label: 'Nuevo' },
  { code: 'course', source: 'play-icon.svg', class: 'orange', label: 'Curso' },
  { code: 'video', source: 'play-video.svg', class: 'blue', label: 'Video' },
  { code: 'article', source: 'award-white.svg', class: 'cian', label: 'Artículo' },
  { code: 'html', source: 'award-white.svg', class: 'cian', label: 'Artículo' },
];

export const ARTICLE_BADGE_INDEX = 2;

export const BUTTON = { STARTED: 'Empezar ahora', CONTINUE: 'Continuar', FINISHED: 'Volver a ver' };

export const TYPE_LESSON = ['video', 'article', 'welcome'];

export const MONTHS = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

export const STATUS_COMPLETED = 'completed';

export const PERSONALIZED_VALUES = {
  dniExample: 'Ej: 12345678',
  ceiExample: 'Ej: 123456789',
  dniLength: 8,
};

export const POWERFULL_WOMEN_CODE = '3019';